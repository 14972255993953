define("discourse/plugins/pirate-speak/lib/discourse-markdown/pirate-speak", ["exports", "pretty-text/pretty-text"], function (_exports, _prettyText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  (0, _prettyText.registerOption)((siteSettings, opts) => {
    opts.features["pirate-speak"] = true;
  });
  function piratize(text) {
    return text.replace(/\b(am|are|is)\b/gi, "be").replace(/ing\b/gi, "in'").replace(/v/gi, "'");
  }
  function setup(helper) {
    helper.addPreProcessor(piratize);
  }
});